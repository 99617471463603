import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Ensure Bootstrap JavaScript is included
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';

import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faArrowDown, faArrowUp, faCode, faMobileAlt, faPencilRuler, faChartLine } from '@fortawesome/free-solid-svg-icons';
import './App.css';

const App = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
    document.getElementById('scrollDownArrow').style.display = 'block';
    document.getElementById('scrollUpArrow').style.display = 'block';
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    document.getElementById('scrollDownArrow').style.display = 'block';
    document.getElementById('scrollUpArrow').style.display = 'block';
  };

  const textArray = [
    { text: "Empowering Your Digital Success", backgroundColor: '#00468b' },
    { text: "Harnessing Technology to Eliminate Obstacles", backgroundColor: '#3D550C' },
    { text: "Bringing Your Vision to Life", backgroundColor: '#6C757D' },
    { text: "Innovative Digital Experiences for You", backgroundColor: '#343A40' }
  ];
  const [currentText, setCurrentText] = useState(0);

  useEffect(() => {
    const textInterval = setInterval(() => {
      setCurrentText((prevText) => (prevText + 1) % textArray.length);
    }, 3000);

    return () => clearInterval(textInterval);
  }, [textArray.length]);

  return (
    <div>
      <header className="text-white py-3 shadow-sm" style={{ backgroundColor: '#FD7F20' }}>
        <nav className="container navbar navbar-expand-lg navbar-dark">
          {/* Logo on the left */}
          <a className="navbar-brand" href="#F1B420">
            <img src="/logo.png" alt="BugXTech Logo" height="80px" width="100px" />
          </a>

          {/* Navbar toggler for mobile view */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navigation links */}
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link text-light" href="#services">
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="#about">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="#projects">
                  Projects
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <section
        id="hero"
        className="d-flex align-items-center justify-content-center position-relative"
        style={{ minHeight: '100vh', backgroundColor: textArray[currentText].backgroundColor, color: '#fff', transition: 'background-color 1s ease-in-out' }}
      >
        <div
          className="overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        ></div>
        <div className="container text-center position-relative" style={{ zIndex: 2 }}>
          <div
            className="hero-content"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              padding: '50px',
              borderRadius: '15px',
              boxShadow: '0 12px 20px rgba(0, 0, 0, 0.6)',
              transition: 'transform 0.3s ease-in-out',
            }}
          >
            <h1
              className="display-3 font-weight-bold mb-4"
              style={{ textShadow: '3px 3px 15px rgba(0, 0, 0, 0.8)', transition: 'color 0.3s ease-in-out' }}
            >
              {textArray[currentText].text}
            </h1>
            <p className="lead mb-4" style={{ fontSize: '1.5rem', lineHeight: '1.8', transition: 'color 0.3s ease-in-out' }}>
              At BugXTech, we harness technology to eliminate obstacles and bring your vision to life.
              Specializing in cutting-edge web and app development, as well as data-driven solutions, we transform your ideas into scalable, secure, and innovative digital experiences.
            </p>
            <a
              href="#contact"
              className="cta-button btn btn-warning btn-lg shadow-lg"
              style={{ padding: '15px 30px', fontSize: '1.2rem', borderRadius: '50px', transition: 'transform 0.3s ease-in-out' }}
              onMouseEnter={(e) => e.target.style.transform = 'scale(1.1)'}
              onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
            >
              Start Your Journey with Us
            </a>
          </div>
        </div>
      </section>



      <section id="services" className="py-5" style={{ backgroundColor: '#FEDE00' }}>
        <div className="container">
          <h2 className="text-center section-heading text-secondary mb-5" style={{ position: 'relative', transition: 'transform 0.3s ease-in-out' }} onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}>🚀 Our Outstanding Services 🚀</h2>
          <div className="row">
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card h-100 text-center shadow-sm service-card" style={{ borderColor: '#007bff' }}>
                <div className="card-body">
                  <FontAwesomeIcon icon={faCode} className="fa-3x text-primary mb-3" />
                  <h3 className="card-title text-primary">Web Development</h3>
                  <p className="card-text">Custom websites built with React.js, designed to provide a seamless and responsive experience for users.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card h-100 text-center shadow-sm service-card" style={{ borderColor: '#28a745' }}>
                <div className="card-body">
                  <FontAwesomeIcon icon={faMobileAlt} className="fa-3x text-success mb-3" />
                  <h3 className="card-title text-success">App Development</h3>
                  <p className="card-text">End-to-end application development using the latest React technologies for iOS and Android platforms.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card h-100 text-center shadow-sm service-card" style={{ borderColor: '#ffc107' }}>
                <div className="card-body">
                  <FontAwesomeIcon icon={faPencilRuler} className="fa-3x text-warning mb-3" />
                  <h3 className="card-title text-warning">Creative UI/UX Design</h3>
                  <p className="card-text">Enhancing the user experience through intuitive interfaces and a modern design approach.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4">
              <div className="card h-100 text-center shadow-sm service-card" style={{ borderColor: '#17a2b8' }}>
                <div className="card-body">
                  <FontAwesomeIcon icon={faChartLine} className="fa-3x text-info mb-3" />
                  <h3 className="card-title text-info">Data Science Mastery</h3>
                  <p className="card-text">Data-driven solutions using Python, R, and Machine Learning to transform data into actionable insights, empowering businesses to make informed decisions.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="py-5" style={{ backgroundColor: '#C8DF52' }}>
        <div className="container">
          <h2 className="text-center section-heading text-secondary mb-5" style={{ position: 'relative', transition: 'transform 0.3s ease-in-out' }} onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}>✨ Get to Know Us Better ✨</h2>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <p className="text-center" style={{ fontSize: '1.2rem', lineHeight: '1.8' }}>At BugXTech, our mission is simple: eliminate bugs and deliver exceptional technology solutions to our clients. Our name, BugXTech, reflects our commitment to eliminating software issues ("Bugs") using cutting-edge technology ("X"), providing reliable and powerful solutions for businesses worldwide.</p>
              <p className="text-center" style={{ fontSize: '1.2rem', lineHeight: '1.8' }}>We are a young and ambitious company, specializing in web and app development, as well as data science projects. Our team combines expertise in modern technologies such as React.js, Node.js, Python, and cloud computing to deliver seamless, responsive, and bug-free products. Whether it's developing custom websites, scalable applications, or using data science to unlock insights, we are driven by innovation and a passion for technology.</p>
              <p className="text-center" style={{ fontSize: '1.2rem', lineHeight: '1.8' }}>We believe in partnering with our clients to create user-centric experiences, leveraging data, creativity, and technology to solve real-world challenges. Our data science services cover everything from data visualization, predictive modeling, and AI solutions, helping businesses to make data-driven decisions and grow.</p>
              <p className="text-center" style={{ fontSize: '1.2rem', lineHeight: '1.8' }}>We understand the importance of building trust and delivering value. At BugXTech, we pride ourselves on creating not only bug-free software but also building solutions that elevate our clients' success.</p>
            </div>
          </div>
        </div>
      </section>

      <section id="projects" className="py-5" style={{ backgroundColor: '#D0C542' }}>
        <div className="container">
          <h2 className="text-center section-heading text-secondary mb-5" style={{ position: 'relative', transition: 'transform 0.3s ease-in-out' }} onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}>🏆 Our Proudly Crafted Projects 🏆</h2>
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100 text-center shadow-sm project-card" style={{ borderColor: '#007bff' }}>
                <div className="card-body">
                  <h3 className="card-title text-primary">DoingThisTogether</h3>
                  <p className="card-text">A versatile and integrated platform designed to support individuals dealing with mental health challenges. Built using React for the frontend and SQL for the backend, it offers tools like PHQ-9 and GAD-7 assessments, personalized remedies, video lectures, patient management, and more.</p>
                  <button className="btn btn-primary mt-3">View Project</button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100 text-center shadow-sm project-card" style={{ borderColor: '#28a745' }}>
                <div className="card-body">
                  <h3 className="card-title text-success">E-Learning Tribal Initiative</h3>
                  <p className="card-text">A transformative educational project aimed at bringing innovative e-learning opportunities to tribal regions. Features include customized curriculum, localized content, interactive learning platforms, cultural preservation, and vocational training.</p>
                  <button className="btn btn-success mt-3">View Project</button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100 text-center shadow-sm project-card" style={{ borderColor: '#ffc107' }}>
                <div className="card-body">
                  <h3 className="card-title text-warning">Project A - E-commerce Experience</h3>
                  <p className="card-text">A fully-featured e-commerce web app, built with React.js to provide a high-performing user experience.</p>
                  <button className="btn btn-warning mt-3">View Project</button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100 text-center shadow-sm project-card" style={{ borderColor: '#17a2b8' }}>
                <div className="card-body">
                  <h3 className="card-title text-info">Project B - Logistics App</h3>
                  <p className="card-text">Mobile application for managing logistics and deliveries, leveraging React Native to ensure cross-platform compatibility.</p>
                  <button className="btn btn-info mt-3">View Project</button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <div className="card h-100 text-center shadow-sm project-card" style={{ borderColor: '#6c757d' }}>
                <div className="card-body">
                  <h3 className="card-title text-secondary">Project C - Dynamic Blog CMS</h3>
                  <p className="card-text">Content management system for a blog site, featuring a beautiful UI and dynamic functionalities created with React.js.</p>
                  <button className="btn btn-secondary mt-3">View Project</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="py-5" style={{ backgroundColor: '#FFD898', color: '#1A4314' }}>
        <div className="container">
          <h2 className="text-center section-heading text-warning mb-5" style={{ position: 'relative', transition: 'transform 0.3s ease-in-out' }} onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'} onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}>📞 Get In Touch With Us Today 📞</h2>
          <div className="row">
            <div className="col-md-6 mb-4">
              <h4>We're Here to Help</h4>
              <p>If you have any questions, feel free to reach out to us. We are always here to help and provide the best solutions for your business needs. You can contact us through the form or reach out directly at:</p>
              <p><strong>Email:</strong> bugsxtech@hotmail.com</p>
              <p><strong>Phone:</strong> +923140541992</p>
              <p><strong>Address:</strong> Islamabad, Pakistan</p>
            </div>
            <div className="col-md-6">
              <form action="#" method="post" className="mx-auto">
                <div className="form-group">
                  <input type="text" name="name" className="form-control form-control-lg" placeholder="Your Name" required />
                </div>
                <div className="form-group">
                  <input type="email" name="email" className="form-control form-control-lg" placeholder="Your Email" required />
                </div>
                <div className="form-group">
                  <input type="text" name="subject" className="form-control form-control-lg" placeholder="Subject" required />
                </div>
                <div className="form-group">
                  <textarea name="message" className="form-control form-control-lg" placeholder="Your Message" rows="5" required></textarea>
                </div>
                <button type="submit" className="btn btn-warning btn-lg btn-block">Send Message</button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <footer className="py-4" style={{ backgroundColor: '#F1B420', color: '#fff' }}>
        <div className="container text-center">
          <div className="social-links mb-3">
            <a href="#" className="mx-2">
              <FontAwesomeIcon icon={faFacebookF} size="2x" />
            </a>
            <a href="#" className="mx-2">
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
            <a href="#" className="mx-2">
              <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
            </a>
            <a href="#" className="mx-2">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
          </div>
          <p>&copy; 2024 BugXTech. All rights reserved.</p>
        </div>
      </footer>


      <button className="scroll-arrow" id="scrollDownArrow" title="Scroll Down" onClick={scrollToBottom}>
        <FontAwesomeIcon icon={faArrowDown} />
      </button>
      <button className="scroll-arrow" id="scrollUpArrow" title="Scroll Up" onClick={scrollToTop}>
        <FontAwesomeIcon icon={faArrowUp} />
      </button>
    </div>
  );
};

export default App;

